import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import userService from "../../Services/user.service";
import "./manageUser.css";
import { connect } from "react-redux";
import {
  addUser,
  getUserList,
} from "../../../redux-container/settings/manage_users/action";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CountrySelect from "../../Commons/CountrySelect";
import { apiCall } from "../../Services/Interceptor";
import toaster from "../../Toast/toaster";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

function AddNewEmpolyee(props) {
  const [inputs, setInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
    secondaryEmail: "",
    mobileNumber: "",
    mobileNumberCountryCode: "",
    phoneNumber: "",
    phoneNumberCountryCode: "",
    phoneNumberStateCode: "",
    phoneNumberExtension: "",
    designation: "",
    roleId: "",
    caller: "",
    live: "",
    userLoginAllow: "",
    status: true,
    managerId: [],
    employeeId: "",
    coeIds: [],
    childrenId: [],
    childrens: [],
    //externalId:1234569
  });

  const [errors, setErrors] = useState({});
  const [roles, setRoles] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [childrens, setChilderns] = useState([]);
  const [coeList, setCoeList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const storedCoeList = sessionStorage.getItem("COE_LIST");
    if (storedCoeList) {
      setCoeList(JSON.parse(storedCoeList));
    }
  }, []);

  const handleChange = (event) => {
    let { name, value, checked } = event.target;

    if (
      event.target &&
      event.target.name != "caller" &&
      event.target.name != "live" &&
      event.target.name != "userLoginAllow"
    ) {
      if (name === "firstName" || name === "lastName") {
        value = value.replace(/[^A-Za-z]/gi, "");
        value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        value = value.replace(/\b\w/g, (match) => match.toUpperCase());
      }
      if (name === "employeeId") {
        value = value.replace(/[^A-Za-z0-9]/gi, "");
      }
      if (name === "email") {
        if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
          setErrors({ email: "Email is invalid(only .,_,@ are allowed)" });
        } else {
          setErrors({});
        }
      }
      if (name === "designation") {
        value = value.replace(/[^A-Za-z0-9]/gi, "");
      }
      if (
        name === "mobileNumber" ||
        name === "phoneNumber" ||
        name === "phoneNumberExtension" ||
        name === "phoneNumberStateCode"
      ) {
        value = value.replace(/[^0-9]/g, "");
        if (name === "mobileNumber") {
          value = value.replace(/[^0-9]/g, "");
        }
        if (value.length > 10) {
          value = value.slice(0, 10);
        }
        if (value.length > 0 && value.length !== 10) {
          setErrors((errors) => ({
            ...errors,
            mobileNumber: "Mobile number must be exactly 10 digits",
          }));
        } else {
          setErrors((errors) => ({ ...errors, mobileNumber: "" }));
        }
      }
    }

    if (name === "roleId") {
      setInputs((prev) => ({
        ...prev,
        roleId: value,
        coeIds: [],
      }));

      apiCall
          .post(`/odio/api/user/list/manager`, { role: value })
          .then((res) => {
            if (res.data && res.data.status == 0) {
              setSuggestions(res?.data?.data?.managersList);
            }
            apiCall
              .post(`/odio/api/user/list/children`, { role: value })
              .then((res) => {
                if (res.data && res.data.status == 0) {
                  setChilderns(res?.data?.data?.childrenList);
                }
              });
          });
        return;
      }

      if (name === "childern") {
        const newSelectedIds = typeof value === "string" ? value.split(",") : value;
        const selectedChildrenData = childrens
          .filter((item) => newSelectedIds.includes(item.id))
          .map((item) => ({
            id: item.id,
            name: item.name
          }));
        
        setInputs((inputs) => ({
          ...inputs,
          childrenId: newSelectedIds,
          childrens: selectedChildrenData.map(child => child.name)
        }));
        return;
      }

    if (name === "coe") {
      const isSingleSelection = inputs.roleId === "2";

      if (isSingleSelection) {
        const singleValue = Array.isArray(value)
          ? value[value.length - 1]
          : value;
        setInputs((prev) => ({
          ...prev,
          coeIds: singleValue ? [singleValue] : [],
        }));
      } else {
        const newSelectedIds = Array.isArray(value) ? value : [value];
        setInputs((prev) => ({
          ...prev,
          coeIds: newSelectedIds,
        }));
      }

      return;
    }

    if (name === "manager") {
      const newSelectedIds =
        typeof value === "string" ? value.split(",") : value;
      const selectedManagers = suggestions
        .filter((item) => newSelectedIds.includes(item.id))
        .map((item) => item.firstName);
      setInputs((inputs) => ({
        ...inputs,
        manager: selectedManagers,
        managerId: newSelectedIds,
      }));
    }

    if (name === "childern") {
      const newSelectedIds =
        typeof value === "string" ? value.split(",") : value;
      const selectedChildern = childrens
        .filter((item) => newSelectedIds.includes(item.id))
        .map((item) => item.firstName);
      setInputs((inputs) => ({
        ...inputs,
        childrens: selectedChildern,
        childrenId: newSelectedIds,
      }));
    }

    if (
      event.target.name == "caller" ||
      event.target.name == "live" ||
      event.target.name == "userLoginAllow"
    ) {
      console.log(checked);
      setInputs((inputs) => ({ ...inputs, [name]: checked }));
    } else setInputs((inputs) => ({ ...inputs, [name]: value }));
  };

  const handleCountryCodeChange = (event) => {
    setInputs((inputs) => ({ ...inputs, phoneNumberCountryCode: event.phone }));
  };
  const handleMobileCountryCodeChange = (event) => {
    setInputs((inputs) => ({
      ...inputs,
      mobileNumberCountryCode: event.phone,
      mobileNumber: ''
    }));
  };

  const formError = {};
  const validate = (values) => {
    if (!values.firstName) {
      formError.firstName = "First Name is required";
    }
    if (!values.lastName) {
      formError.lastName = "Last Name is required";
    }
    if (!values.employeeId) {
      formError.employeeId = "Employee Id is required";
    }
    if (!values.email) {
      formError.email = "Email is required";
    }
    if (!values.designation) {
      formError.designation = "Designation is required";
    }
    if (values.mobileNumber) {
      if (!values.mobileNumberCountryCode)
        formError.mobileNumberCountryCode = "Please enter Country Code";

      if (values.mobileNumberCountryCode === "91") {
        if (
          values.mobileNumber.length < 10 ||
          values.mobileNumber.length > 10
        ) {
          formError.mobileNumber = "Please enter 10 digits";
        }
        if (
          !/^[6-9]\d{9}$/gi.test(values.mobileNumber) &&
          values.mobileNumber.length === 10 &&
          values.mobileNumber[0] !== 0
        ) {
          formError.mobileNumber = "Please enter correct mobile number";
        }
        if (values.mobileNumber[0] == 0) {
          formError.mobileNumber = "Mobile Number cannot start with 0";
        }
      }
    } else {
      formError.mobileNumber = "Please enter mobile number";
    }
    if (values.phoneNumber) {
      if (!values.phoneNumberCountryCode) {
        formError.phoneNumberCountryCode = "Please Give Country Code";
      }
      if (values.phoneNumber[0] == 0)
        formError.phoneNumber = "Phone Number cannot start with 0";
      if (values.phoneNumberCountryCode === "+91") {
        if (values.phoneNumber.length < 10 || values.phoneNumber.length > 10)
          formError.phoneumber = "Please enter 10 digits";
      }
    }

    if (!values.roleId) {
      formError.roleId = "Please select role";
    }

    if (!values.coeIds || values.coeIds.length === 0) {
      formError.coe = "COE selection is required";
    }

    if (!values.mobileNumber) {
      formError.mobileNumber = "Please enter a mobile number";
    } else if (values.mobileNumber.length !== 10) {
      formError.mobileNumber = "Mobile number must be exactly 10 digits";
    } else if (!/^[6-9]\d{9}$/.test(values.mobileNumber)) {
      formError.mobileNumber = "Mobile number must start with 6, 7, 8, or 9";
    }

    return formError;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    validate(inputs);
    setErrors(formError);
    
    if (Object.keys(formError).length === 0) {
      setIsSubmitting(true);
      
      try {
        const res = await apiCall.post("/odio/api/user/create", inputs);
        
        if (res.data.status === 0) {
          props.setIsOpenFalse();
          toaster.success("Success");
          setTimeout(() => {
            props.getUserList();
          }, 1000);
        } else if (res.data.status === 1) {
          toaster.error(res.data.message);
        } else if (res?.data?.status === 500) {
          toaster.error(res?.data?.message);
        }
      } catch (error) {
        console.log(error);
        props.setIsOpenTrue();
        toaster.error("An error occurred while submitting the form");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const getRoleOptions = () => {
    userService.getRoleOptions().then((res) => {
      if (res) setRoles(res.data.data);
    });
  };

  useEffect(() => {
    document.title = "New User - Odio";
    getRoleOptions();
  }, []);

  return (
    <div className="mt-3 addEmploy-form">
      <div className="d-flex align-items-center">
        <div>
          <i className="bx bxs-user me-1 font-22 edit-profile-icon" />
        </div>
        <h5 className="mb-0 edit-profile-header">New User</h5>
      </div>
      <hr />
      <form className="row g-3" onSubmit={handleFormSubmit}>
        <div className="col-md-6">
          <label htmlFor="inputEMPFirstName" className="form-label">
            First Name*
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent">
              <i className="bx bxs-user" />
            </span>
            <input
              type="text"
              className="form-control"
              id="inputFirstName"
              placeholder="First Name"
              name="firstName"
              value={inputs.firstName}
              onChange={handleChange}
              maxLength="32"
            />
          </div>
          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {errors.firstName && (
              <span style={{ color: "red" }}>{errors.firstName}</span>
            )}
          </p>
        </div>

        <div className="col-md-6">
          <label htmlFor="inputEMPLastName" className="form-label">
            Last Name*
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent">
              <i className="bx bxs-user" />
            </span>
            <input
              type="text"
              className="form-control"
              id="inputLastName"
              placeholder="Last Name"
              name="lastName"
              value={inputs.lastName}
              onChange={handleChange}
              maxLength="32"
            />
          </div>
          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {errors.lastName && (
              <span style={{ color: "red" }}>{errors.lastName}</span>
            )}
          </p>
        </div>

        <div className="col-md-6 mt-0">
          <label htmlFor="inputEMPcode" className="form-label">
            Employee Id*
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent">
              <i className="fadeIn animated bx bx-hash" />
            </span>
            <input
              type="text"
              name="employeeId"
              value={inputs.employeeId}
              onChange={handleChange}
              className="form-control"
              id="inputEMPcode"
              placeholder="employeeId"
              maxLength="32"
            />
          </div>
          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {errors.employeeId && (
              <span style={{ color: "red" }}>{errors.employeeId}</span>
            )}
          </p>
        </div>

        <div className="col-md-6 mt-0">
          <label htmlFor="inputEMPEmail" className="form-label">
            Email*
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent">
              <i className="lni lni-envelope" />
            </span>
            <input
              type="email"
              className="form-control"
              id="inputEmail"
              placeholder="example@user.com"
              name="email"
              value={inputs.email}
              onChange={handleChange}
              maxLength="64"
            />
          </div>
          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {errors.email && (
              <span style={{ color: "red" }}>{errors.email}</span>
            )}
          </p>
        </div>

        <div className="col-md-6 mt-0">
          <label htmlFor="role" className="form-label">
            Role*
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent">
              <i className="bx bxs-user" />
            </span>
            <div className="form-select mui-selectedbox">
              <Select
                id="inputRole"
                className="form-select"
                placeholder="Role"
                value={inputs.roleId}
                onChange={handleChange}
                name="roleId"
              >
                {roles.map((item, i) => (
                  <MenuItem key={i} value={item.id}>
                    {item.roleName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {errors.roleId && (
              <span style={{ color: "red" }}>{errors.roleId}</span>
            )}
          </p>
        </div>
        <div className="col-md-6 mt-0">
          <label htmlFor="Designation" className="form-label">
            Designation
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent">
              <i className="bx bxs-user"></i>
            </span>
            <input
              type="text"
              className="form-control"
              id="inputDesignation"
              placeholder="Designation"
              name="designation"
              value={inputs.designation}
              onChange={handleChange}
            />
          </div>
          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {errors.designation && (
              <span style={{ color: "red" }}>{errors.designation}</span>
            )}
          </p>
        </div>

        <div className="col-md-6">
          <label htmlFor="inputEMPphone" className="form-label">
            Phone
          </label>
          <div className="row g-3">
            <div className="col-md-6">
              <CountrySelect
                value={inputs.phoneNumberCountryCode}
                name="phoneNumberCountryCode"
                handleCountryCodeChange={handleCountryCodeChange}
              />
              <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
                {" "}
                {errors.phoneNumberCountryCode && (
                  <span style={{ color: "red" }}>
                    {errors.phoneNumberCountryCode}
                  </span>
                )}
              </p>
            </div>
            <div className="col-md-6">
              <input
                type="num"
                className="form-control"
                id="stateCode"
                placeholder="Std Code"
                name="phoneNumberStateCode"
                value={inputs.phoneNumberStateCode}
                onChange={handleChange}
                maxLength="10"
              />
            </div>
            <div className="col-md-6">
              <div className="input-group">
                <span className="input-group-text bg-transparent">
                  <i className="lni lni-phone-set" />
                </span>
                <input
                  type="num"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  value={inputs.phoneNumber}
                  onChange={handleChange}
                  maxLength="10"
                />
              </div>
            </div>
            <div className="col-md-6">
              <input
                type="extNum"
                name="phoneNumberExtension"
                value={inputs.phoneNumberExtension}
                maxLength="6"
                onChange={handleChange}
                className="form-control"
                id="ext-link"
                placeholder="Ext"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <label htmlFor="inputEMPmob" className="form-label">
            Mobile*
          </label>
          <div className="row g-3">
            <div className="col-md-6">
              <CountrySelect
                value={inputs.mobileNumberCountryCode}
                name="mobileNumberCountryCode"
                handleMobileCountryCodeChange={handleMobileCountryCodeChange}
              />
              <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
                {errors.mobileNumberCountryCode && (
                  <span style={{ color: "red" }}>
                    {errors.mobileNumberCountryCode}
                  </span>
                )}
              </p>
            </div>
            <div className="col-md-6">
              <div className="input-group">
                <span className="input-group-text bg-transparent">
                  <i className="lni lni-mobile" />
                </span>
                <input
                  type="tel"
                  className="form-control"
                  placeholder="Mobile Number"
                  name="mobileNumber"
                  value={inputs.mobileNumber}
                  onChange={handleChange}
                  maxLength="10"
                />
              </div>
              <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
                {errors.mobileNumber && (
                  <span style={{ color: "red" }}>{errors.mobileNumber}</span>
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6" id="inputManager">
          <label htmlFor="Manager" className="form-label">
            Manager
          </label>
          <br />

          <div className="input-group">
            <div className="form-select mui-selectedbox">
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                className="form-select"
                multiple
                value={inputs.managerId || []}
                onChange={handleChange}
                name="manager"
                renderValue={(selected) => {
                  const selectedNames = suggestions
                    .filter((item) => selected.includes(item.id))
                    .map((item) => item.name)
                    .join(", ");
                  return (
                    <div
                      style={{
                        maxWidth: "calc(100% - 35px)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedNames}
                    </div>
                  );
                }}
                title={
                  inputs?.managerId?.length > 0
                    ? suggestions
                        .filter((item) => inputs.managerId.includes(item.id))
                        .map((item) => item.name)
                        .join(", ")
                    : "No items selected"
                }
              >
                {suggestions.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={inputs.managerId.includes(item.id)} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {inputs.roleId == "" && (
              <span style={{ color: "red" }}>Please select role first</span>
            )}
          </p>
        </div>

        <div className="col-md-6">
          <label htmlFor="coe" className="form-label">
            COE*
          </label>
          <div className="input-group">
            <span className="input-group-text bg-transparent">
              <i className="bx bxs-building" />
            </span>
            <div className="form-select mui-selectedbox">
              <Select
                id="inputCOE"
                className="form-select"
                placeholder="COE"
                multiple={inputs.roleId != "2"}
                value={inputs.coeIds}
                onChange={handleChange}
                name="coe"
                renderValue={(selected) => {
                  const selectedNames = coeList
                    .filter((item) => selected.includes(item.coeId))
                    .map((item) => item.coeName)
                    .join(", ");
                  return (
                    <div
                      style={{
                        maxWidth: "calc(100% - 35px)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedNames}
                    </div>
                  );
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                    },
                  },
                }}
              >
                {coeList.map((item) => (
                  <MenuItem key={item.coeId} value={item.coeId}>
                    {inputs.roleId !== "2" && (
                      <Checkbox checked={inputs.coeIds.includes(item.coeId)} />
                    )}
                    <ListItemText primary={item.coeName} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {errors.coe && <span style={{ color: "red" }}>{errors.coe}</span>}
          </p>
        </div>

        <div className="col-md-6" id="inputManager">
          <label htmlFor="Manager" className="form-label">
            Descends
          </label>
          <br />

          <div className="input-group">
            <div className="form-select mui-selectedbox">
              <Select
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                className="form-select"
                multiple
                value={inputs.childrenId || []}
                onChange={handleChange}
                name="childern"
                renderValue={(selected) => {
                  const selectedNames = childrens
                    .filter((item) => selected.includes(item.id))
                    .map((item) => item.name)
                    .join(", ");
                  return (
                    <div
                      style={{
                        maxWidth: "calc(100% - 35px)",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedNames}
                    </div>
                  );
                }}
                title={
                  inputs?.childrenId?.length > 0
                    ? childrens
                        .filter((item) => inputs.childrenId.includes(item.id))
                        .map((item) => item.name)
                        .join(", ")
                    : "No items selected"
                }
              >
                {childrens.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox checked={inputs.childrenId.includes(item.id)} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <p className="my-1 mb-0 lh-1 font-12" style={{ height: "18px" }}>
            {inputs.roleId == "" && (
              <span style={{ color: "red" }}>Please select role first</span>
            )}
          </p>
        </div>

        <div className="col-md-6">
          <div className="row">
            <div className="col-md-2">
              <label htmlFor="Manager" className="form-label">
                Caller
              </label>
              <div className="form-check form-switch font-30">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="caller"
                  name="caller"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-2">
              <label htmlFor="Manager" className="form-label">
                Live
              </label>
              <div className="form-check form-switch font-30">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="liveSwitch"
                  name="live"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-md-5">
              <label htmlFor="Manager" className="form-label">
                Login Allowed
              </label>
              <div className="form-check form-switch font-30">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="liveSwitch"
                  name="userLoginAllow"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 text-sm-right cancelUpadte-btn text-end">
          <Button
            className="me-2 px-4 lh-base"
            variant="light"
            onClick={() => props.setIsOpenFalse()}
            disabled={isSubmitting}
          >
            <span>
              <i className="bx bx-arrow-back me-1 blk_clr"></i>
            </span>
            Cancel
          </Button>
          <Button type="submit" className="update-btn px-5 lh-base border-0" disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </div>
      </form>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data) => {
      dispatch(addUser(data));
    },
    getUserList: () => {
      dispatch(getUserList());
    },
  };
};

export default connect(null, mapDispatchToProps)(AddNewEmpolyee);
