import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import React, { useState } from "react";
import DashboardSales from "../Dashboard/DashboardSales";
import DashboardSupport from "../Dashboard/DashboardSupport";
import Team from "../Team/Team";
import Notifications from "../Notifications/Notifications";
import Productivity from "../Insights/Productivity/Productivity";
import ActivityLog from "../Insights/ActivityLog/ActivityLog";
import ConversationMetrics from "../Insights/ConversationMetrics/ConversationMetrics";
import DurationMetrics from "../Insights/DurationMetrics/DurationMetrics";
import Header from "./Header";
import Footer from "./Footer";
import SideBar from "./SideBar";
import Login from "../Login/Login";
import ResetPassword from "../Login/ResetPassword";
import CompanySidebar from "../CompanySettings/CompanySidebar";
import ProfileSidebar from "../Profile/ProfileSidebar";
import ForgotPassword from "../Login/ForgotPassword";
import Profile from "../Commons/Profile";
import {
  AUTHENTICATED,
  USER_DETAIL,
  USER_PERMISSIONS,
} from "../../Constants/constant";
import { connect } from "react-redux";
import ConversationSales from "../Conversations_Dashboard/Sales/ConversationSales";
import Reports from "../Reports/Reports";
import {
  setUserDetails,
  setUserPermissions,
  getPermissions,
  logout,
} from "../../redux-container/login/action";
import ComparativeAnalysis from "../Insights/ComparativeAnalysis/ComparativeAnalysis";
import { SET_USERNAME, SIGN_IN } from "../../redux-container/login/types";
import SalesCallDetail from "../Conversations_Dashboard/Sales/SalesCallDetail";
import ConversationSupport from "../Conversations_Dashboard/Sales/ConversationSupport";
import TeamMemberDetail from "./AgentDetail/TeamMemberDetail";
import GLIDUpload from "../../Separate-pages/GLIDUpload";
import RecordingSubmit from "../../Separate-pages/RecordingSubmit"; 
import { usePreviousValue } from "../Notifications/customHook/usePreviousValue";
import VoiceDetail from "../Conversations_Dashboard/Voice/VoiceDetail";
import VoiceListingPage from "../Conversations_Dashboard/Voice/VoiceListingPage";
import ChatListPage from "../Conversations_Dashboard/Chat/ChatListPage";
import ChatDetail from "../Conversations_Dashboard/Chat/ChatDetail";
import EmailList from "../Conversations_Dashboard/Email/EmailList";
import RedirectToTranscript from "../../Separate-pages/UploadCall/RedirectToTranscript";
import SlackIntegration from "./SlackIntegration";
import { useHistory } from 'react-router-dom';
import EmailDetail from "../Conversations_Dashboard/Email/EmailDetail";
import { useDataState } from "../Dashboard/DashboardSales/SalesDashboard/WordCloudApiState";
import QuestionsTab from "../Insights/Questions/QuestionsTab";
import KnowledgeAI from "../KnowledgeAI/KnowledgeAI";
import LiveAssist from "../LiveAssist/LiveAssist";
import Meeting from "../Meetings/Meeting/Meeting";
import MeetingDetail from "../Meetings/MeetingDetail/MeetingDetail";
import MeetingCallDetail from "../Meetings/MeetingDetail/MeetingCallDetail";
import LiveAssistCallDetails from '../LiveAssist/LiveAssistCallDetails';
import CoachingDashboard from "../Coaching/CoachingDashboard/CoachingDashboard";
import CoachingUserStats from "../Coaching/CoachingStats/CoachingUserStats";
import LiveAssistCallDetailMobileView from "../LiveAssist/LiveAssistMobileView/LiveAssistCallDetailMobileView";
import CoachingKnowledgeBase from "../Coaching/KnowledgeBase/CoachingKnowledgeBase";
import SessionInfoPage from "../Coaching/CoachingSessions/SessionInfoPage";
import AddDocument from "../Coaching/KnowledgeBase/AddDocument";
import PlayButton from "../LiveAssist/PlayButton";
// import AudioStreamSender from "../LiveAssist/AudioStreamSender";
import { AudioStreamerPlay } from "../LiveAssist/AudioStreamerPlay";
import EditQuestionModal from "../Coaching/KnowledgeBase/EditQuestionModal";
import ViewDocument from "../Coaching/CoachingStats/ViewDocument";
import FolderDocumentList from "../Coaching/KnowledgeBase/FolderDocumentList";
import QuestionBankDetail from "../Coaching/KnowledgeBase/QuestionBankDetail";
import NewFooter from "./NewFooter";


function Routing(props) {
  const location = useLocation()
  const history = useHistory()
  const [show, setShow] = useState(true);
  let routingPermission = JSON.parse(localStorage.getItem("ROUTING_PERMISSIONS"))
  let details = JSON.parse(localStorage.getItem(USER_DETAIL))
  let defaultRoutes = [{"api":'/'},{"api":'/home'},{"api":'/email_detail'},{"api":'/coaching_dashboard'},{"api":'/live_assist_call_details'},{"api":'/knowledge_base'},{'api':'/knowledge_base/qBank'},{'api':'/knowledge_base/questions'},{"api":'/session_info'},{"api":"/meeting_view_detail"},{"api":"/coaching_user_stats"},{"api":"/coaching_team_stats"},{"api":"/live_call"}/*,{"api":"/live_assist/play/audio_stream_sender"}*/,{"api":"/live_assist/play/audio_streamer_play"},{'api':'/play'},{"api":"/document_add"},{"api":"/document_view"},{"api":'/document_list'}]
  let wordData = useDataState()

  const checkAgent = (data)=>{
    if (data && data.Conversations && Array.isArray(data.Conversations) && data.Conversations.length > 1) {
      return true;
    } else {
      return false;
    }
  }

  const checkRole = ()=>{
      const permissions = Object.values(routingPermission).flat()
      var modifiedpermissions = [...defaultRoutes, ...permissions]
      let result = modifiedpermissions.some((apiRoute)=>{
        return apiRoute.api === history.location.pathname
      })
      return result
  }

  let handleLogout = (authaSatatus)=> {
    props.logout(authaSatatus);
  }
  
  const pageAccessControl = (component) => {
    let auth = localStorage.getItem(AUTHENTICATED);
    if (auth) {
      let permissions = localStorage.getItem(USER_PERMISSIONS);
      props.setUserDetails(details);
      props.setUserPermission(permissions);
      var q = JSON.parse(permissions)
      let data = {
        clientName: details.clientName,
        clientLogo: details.clientLogo,
        role : details.userRole
      };
      
      props.onRefresh(data);
      props.onUpdate(details.userFirstName);
    }
    
  return auth ? checkRole() ? component :  auth == null ? handleLogout(auth) : checkRole() ?  component : handleLogout(auth):  Login;

};

  const sideBarHovered = () => {
    document
      .getElementsByClassName("wrapper")[0]
      .classList.add("sidebar-hovered");
  };
  const sideBarUnhovered = () => {
    document
      .getElementsByClassName("wrapper")[0]
      .classList.remove("sidebar-hovered");
  };

  const hideToShow = () => {
    setShow(!show);
    if (show == false) {
      document.getElementsByClassName("wrapper")[0].classList.add("toggled");
    } else {
      document.getElementsByClassName("wrapper")[0].classList.remove("toggled");
    }
  };

  let old_url = usePreviousValue(window.location.pathname)
  
  return (
    <div className="wrapper toggled">
      <ConnectedRouter history={history}>
        {props.authenticate && (
          <SideBar
            hideToShow={hideToShow}
            sideBarHovered={sideBarHovered}
            sideBarUnhovered={sideBarUnhovered}
          />
        )}
        {props.authenticate&& <Header hideToShow={hideToShow}/>}

        <Switch>   
          
          <Route
            exact
            path="/login"
            component={pageAccessControl(details?.userRole==='ADMIN' || details?.userRole==='MANAGER'|| details?.userRole==='QUALITY_MANAGER'||details?.userRole==='QUALITY_MANAGER_HEAD' || details?.userRole=== 'QUALITY_ASSOCIATE' || details?.userRole === 'QUALITY_TRAINER' || details?.userRole==='SUPERVISOR' || details?.userRole==='TEAM_LEAD'? details?.accountType=='SALES' ? DashboardSales : DashboardSupport : details?.accountType=='SALES' ? !routingPermission?.Conversations ? CoachingUserStats : checkAgent(routingPermission) ? VoiceListingPage : ConversationSales : ConversationSupport )}
          />
          
          <Route
            exact
            path="/"
            authenticated={props.authenticate}
            component={pageAccessControl(details?.userRole==='ADMIN'? details?.accountType=='SALES' ? DashboardSales : DashboardSupport : details?.accountType=='SALES' ? !routingPermission?.Conversations ? CoachingUserStats : checkAgent(routingPermission) ? VoiceListingPage : ConversationSales : ConversationSupport )}
          />
          <Route
            exact
            path="/home"
            authenticated={props.authenticate}
            component={pageAccessControl(details?.userRole==='ADMIN'? details?.accountType=='SALES' ? DashboardSales : DashboardSupport : details?.accountType=='SALES' ? !routingPermission?.Conversations ? CoachingUserStats : checkAgent(routingPermission) ? VoiceListingPage : ConversationSales : ConversationSupport )}
          />
          <Route
            exact
            path="/sales_dashboard"
            authenticated={props.authenticate}
            // component={pageAccessControl(details?.role === 'ADMIN'?DashboardSales:'')}
            component={pageAccessControl(DashboardSales)}
          />
         
          <Route
            exact
            path="/support_dashboard"
            authenticated={props.authenticate}
            // component={pageAccessControl(details?.role === 'ADMIN'?DashboardSupport:'')}
            component={pageAccessControl(DashboardSupport)}
          />

          <Route
            exact
            path="/sales_conversations"
            authenticated={props.authenticate}
            component={pageAccessControl(ConversationSales)}
          />
            <Route
            exact
            path="/sales_call_detail"
            authenticated={props.authenticate}
            component={pageAccessControl(SalesCallDetail)}
          />
          {/* <Route
            exact
            path="/sales_call_detail_group"
            authenticated={props.authenticate}
            component={pageAccessControl(GroupCallDetail)}
          /> */}
          <Route
            exact
            path="/support_conversations"
            authenticated={props.authenticate}
            component={pageAccessControl(ConversationSupport)}
          />
          <Route
            exact
            path="/voice"
            authenticated={props.authenticate}
            component={pageAccessControl(VoiceListingPage)}
          />
          <Route
            exact
            path="/voice_detail"
            authenticated={props.authenticate}
            component={pageAccessControl(VoiceDetail)}
          />
          <Route
            exact
            path="/chat"
            authenticated={props.authenticate}
            component={pageAccessControl(ChatListPage)}
          />
          <Route
            exact
            path="/chat_detail"
            authenticated={props.authenticate}
            component={pageAccessControl(ChatDetail)}
          />
          <Route
            exact
            path="/email"
            authenticated={props.authenticate}
            component={pageAccessControl(EmailList)}
          />
          <Route
            exact
            path="/email_detail"
            authenticated={props.authenticate}
            component={pageAccessControl(EmailDetail)}
          />
          <Route
            exact
            path="/meeting"
            authenticated={props.authenticate}
            component={pageAccessControl(Meeting)}
          />
          <Route
            exact
            path="/meetingView"
            authenticated={props.authenticate}
            component={pageAccessControl(MeetingDetail)}
          />
          <Route
            exact
            path="/meeting_view_detail"
            authenticated={props.authenticate}
            component={pageAccessControl(MeetingCallDetail)}
          />
          <Route
            exact
            path="/reset_password"
            authenticated={props.authenticate}
            component={ResetPassword}
          />
          {/* <Route
            exact
            path="/add_new_employee"
            authenticated={props.authenticate}
            component={pageAccessControl(AddNewEmpolyee)}
          /> */}
          <Route
            exact
            path="/profile"
            authenticated={props.authenticate}
            component={pageAccessControl(Profile)}
          />

          {/* <Route
            exact
            path="/sales_interaction_calls"
            authenticated={props.authenticate}
            component={pageAccessControl(SalesInteractionCalls)}
          />
          <Route
            exact
            path="/sales_interaction_video_calls"
            authenticated={props.authenticate}
            component={pageAccessControl(SalesInteractionVideocalls)}
          />

          <Route
            exact
            path="/support_interaction_calls"
            component={SalesInteractionCalls}
          />
          <Route
            exact
            path="/support_interaction_video_calls"
            component={SalesInteractionVideocalls}
          />
          <Route
            exact
            path="/support_interaction_email"
            component={SalesInteractionEmail}
          />
          <Route
            exact
            path="/support_interaction_chat"
            component={SalesInteractionChat}
          /> */}

          <Route
            exact
            path="/conversion_metrics"
            authenticated = {props.authenticate}
            component={pageAccessControl(ConversationMetrics)}
          />
          <Route exact path="/duration_metrics" authenticated={props.authenticate} component={pageAccessControl(DurationMetrics)} />
          <Route exact path="/productivity" authenticated={props.authenticate} component={pageAccessControl(Productivity)} />
          <Route exact path="/activity_log" authenticated={props.authenticate} component={pageAccessControl(ActivityLog)} />
          <Route exact path="/questions" authenticated={props.authenticate} component={pageAccessControl(QuestionsTab)} />
          <Route
            exact
            path="/comparative_analysis"
            authenticated={props.authenticate}
            component={pageAccessControl(ComparativeAnalysis)}
          />
          {/* <Route exact path="/objections" component={Objections} />
          <Route exact path="/follow_ups" component={FollowUps} /> */}

          <Route
            exact
            path="/team_member_detail"
            authenticated={props.authenticate}
            component={pageAccessControl(TeamMemberDetail)}
          />
          <Route exact path="/team"  
          authenticated={props.authenticate}
          component={pageAccessControl(details?.userRole!= 'AGENT'? Team :TeamMemberDetail)} 
          />
          {/* <Route exact path="/notifications" authenticated={props.authenticate} component={pageAccessControl(Notifications)} /> */}
        
          {/* <Route
            exact
            path="/manage_users"
            authenticated={props.authenticate}
            component={pageAccessControl(ManageUsers)}
          /> */}

          <Route
            exact
            path="/setting"
            authenticated={props.authenticate}
            component={pageAccessControl(CompanySidebar)}
          />
          {/* <Route
            exact
            path="/manage_emp"
            authenticated={props.authenticate}
            component={pageAccessControl(ManageEmp)}
          /> */}
          <Route
            exact
            path="/account"
            authenticated={props.authenticate}
            component={pageAccessControl(ProfileSidebar)}
          />
          {/* <Route
            exact
            path="/add_emp"
            authenticated={props.authenticate}
            component={pageAccessControl(AddNewEmpolyee)}
          /> */}

          <Route
            exact
            path="/notifications"
            authenticated={props.authenticate}
            component={pageAccessControl(Notifications)}
          />
          <Route
            exact
            path="/reports"
            authenticated={props.authenticate}
            component={pageAccessControl(Reports)}
          />
          {/* <Route
            exact
            path="/faq"
            authenticated={props.authenticate}
            component={pageAccessControl(Faq)}
          />
          <Route
            exact
            path="/contact_support"
            authenticated={props.authenticate} 
            component={pageAccessControl(ContactSupport)}
          /> */}
          <Route
            exact
            path="/coaching_dashboard"
            authenticated={props.authenticate}
            component={pageAccessControl(CoachingDashboard)}
          />
          {/* <Route
            exact
            path="/coaching_team_stats"
            authenticated={props.authenticate}
            component={pageAccessControl(CoachingTeamStats)}
          /> */}
          <Route 
             exact
             path="/coaching_user_stats"
             authenticated={props.authenticate}
             component={pageAccessControl(CoachingUserStats)}
          />
          <Route
            exact
            path="/knowledge_base"
            authenticated={props.authenticate}
            component={pageAccessControl(CoachingKnowledgeBase)} 
          />
          <Route
            exact
            path="/knowledge_base/qBank"
            authenticated={props.authenticate}
            component={pageAccessControl(EditQuestionModal)} 
          />
          <Route
            exact
            path="/knowledge_base/questions"
            authenticated={props.authenticate}
            component={pageAccessControl(QuestionBankDetail)} 
          />
          <Route
            exact
            path="/session_info"
            authenticated={props.authenticate}
            component={pageAccessControl(SessionInfoPage)}
          />
          <Route 
            exact
            path='/document_list'
            authenticated={props.authenticate}
            component={pageAccessControl(FolderDocumentList)}
          />
            <Route
            exact
            path='/live_assist/play/audio_streamer_play'
            component={AudioStreamerPlay}
            />
            <Route path="/play" component={PlayButton}/>
          {/* <Route 
            exact 
            path="/live_assist/play/audio_stream_sender" 
            authenticated={props.authenticate} 
            component={pageAccessControl(AudioStreamSender)}
          /> */}
          <Route exact path='/document_add' authenticated={props.authenticate} component={pageAccessControl(AddDocument)} />
          <Route exact path='/document_view' authenticated={props.authenticate} component={pageAccessControl(ViewDocument)} />
          <Route exact path='/upload_GLID' authenticated={props.authenticate} component={pageAccessControl(GLIDUpload)} />
          <Route exact path='/knowledge_ai' authenticated={props.authenticate} component={pageAccessControl(KnowledgeAI)}/>
          <Route exact path='/live_assist' authenticated={props.authenticate} component={pageAccessControl(LiveAssist)} />
          <Route exact path='/live_assist_call_details' authenticated={props.authenticate} component={pageAccessControl(LiveAssistCallDetails)} />
          <Route exact path='/live_call' component={LiveAssistCallDetailMobileView} />
          <Route exact path='/upload_call' component={RecordingSubmit} />
          <Route exact path="/forgot_password" component={ForgotPassword} />
          <Route exact path="/redirect_transcript" component={pageAccessControl(RedirectToTranscript)}/>
          <Route exact path='/integration_slack' component={pageAccessControl(SlackIntegration)} />

          <Route path='*' component={Login}/>
        </Switch>

        {/* {details?.userExternalId == '67750229' ? < NewFooter /> : <Footer /> } */}
        {/* {details?.userExternalId == '67750229' ? < NewFooter /> : null} */}
        {['75612601','24888717'].includes(`${details?.externalId}`) ? <NewFooter /> : null}
      </ConnectedRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authenticate: state.login.loggedIn,
    userPermission: state.login.userPermission,
    navShow: state.login.headerShow
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setUserDetails: () => {
      dispatch(setUserDetails(ownProps.details));
    },
    setUserPermission: () => {
      dispatch(setUserPermissions(ownProps.permissions));
    },
    getPermission: () => {
      dispatch(getPermissions(ownProps.detail));
    },
    onRefresh: (inputs) => dispatch({ type: SIGN_IN, payload: inputs }),
    onUpdate: (inputs) => dispatch({ type: SET_USERNAME, payload: inputs }),
    logout: (auth) => dispatch(logout(auth))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
