import React, { useEffect, useState, useMemo } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
} from "react-table";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import CommonSwitchCase from "../CommonSwitchCase";
import Pagination from "./Pagination";
import ReactTooltip from "react-tooltip";
import "./table.css";
import clientService from "../../Services/client.service";
import { connect } from "react-redux";
import BubbleLoader from "../BubbleLoader";
import { useHistory } from "react-router-dom";

let PageSize = 10;
function Table({
  columns,
  data,
  componentType,
  isEdit,
  isDelete,
  isAdd,
  isReco,
  isExcelPdfButton,
  onVisible,
  filteredData,
  filteredUserData,
  filteredTeamData,
  teamStats,
  handlePageIndex,
  prePageIndex,
  prePageIndexx,
  isDisable,
  onRowClick,
  getCompetitorsList,
  openSessionInfo,
  getFoldersList,
  folderId,
  getAllCoachingSessionsList,
  prevButton
}) {
  const [filterInput, setFilterInput] = useState("");
  const [buttonValue, setButtonValue] = useState("");
  const [selectedRowData, setSelectedRowData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [isExcel, setIsExcel] = useState(true);
  const [isIconDisable, setIsIconDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  // Use the state and functions returned from useTable to build your UI
  const dataLength = data?.length;
  const currentPage = 3;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    //rows,
    page,
    nextPage,
    previousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
    canNextPage,
    allColumns,
    prepareRow,
    canPreviousPage,
    setPageSize,
    setFilter,
    pageRows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: prePageIndexx ? prePageIndexx : 0 },
      initialState: { dataIndex: 0 },
      autoResetPage: false,
    },
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect
  );
  const [currentPages, setCurrentPages] = useState(currentPage);
  useEffect(() => {
    gotoPage(prePageIndexx ? prePageIndexx : prePageIndex);
    // gotoPage(1);
    setCurrentPages(data ? Math.round(data.length / PageSize) : 0);
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPages - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data.slice(firstPageIndex, lastPageIndex);
  }, [currentPages]);

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setFilter("show.name", value);
    setFilterInput(value);
  };

  const pageFunction = () => {
    setResetPagination(true);
  };
  const handlePageIndex1 = (e) => {
    handlePageIndex && handlePageIndex(e);
    gotoPage(e);
    onRowClick && onRowClick(e);
  };
  const { pageIndex, dataIndex } = state;

  const localData = JSON.parse(localStorage.getItem("AGENT"));
  const queryParams = new URLSearchParams(window.location.search);
  const dashboardCaller = queryParams.get("order");

    function setView(buttonValue, selectedRowData) {
        setIsOpen(true);
        setSelectedRowData(selectedRowData);
        setButtonValue(buttonValue);
    }
    const createExcel = async () => {
        setLoading(true);
    try {
      if (
        componentType.includes("COE") &&
        !componentType.includes("COE_USERS")
      ) {
        await clientService.getExcelFile("COE", data);
      }
      if (
        componentType.includes("USER") &&
        !componentType.includes("COE_USERS")
      ) {
        await clientService.getExcelFile("USER", filteredUserData);
      }
      if (componentType.includes("MyTEAM")) {
        await clientService.getExcelFile("MyTEAM", filteredData, localData);
      }
      if (componentType.includes("COE_USERS")) {
        await clientService.getExcelFile("COE_USERS", data[0].coeId);
      }
      if (componentType.includes("TEAM") && !componentType.includes("MyTEAM")) {
        await clientService.getTeamExcel(
          "TEAM",
          filteredTeamData,
          teamStats,
          localData?.fromD,
          localData?.toD,
          localData?.selectedCoeRange,
          localData?.callScore,
          localData?.pitchScore,
          localData?.aht,
          localData.callsFrom ? localData?.callsFrom : "",
          localData.callsTo ? localData?.callsTo : "",
          dashboardCaller == "DESC"
            ? "top_caller"
            : dashboardCaller == "ASCE"
            ? "bottom_caller"
            : ""
        );
      }
      if (componentType.includes("PRODUCTIVITY")) {
        await clientService.getExcelFile("PRODUCTIVITY");
      }
      if (componentType.includes("CONVERSION_METRICS")) {
        await clientService.getExcelFile("CONVERSION_METRICS");
      }
      if (componentType.includes("DURATION_METRICS")) {
        await clientService.getExcelFile("DURATION_METRICS");
      }
    } catch (error) {
      console.error("Error creating Excel file:", error);
    }
    await setLoading(false);
  };

  const createPdf = async () => {
    setLoading(true);
    try {
      if (
        componentType.includes("COE") &&
        !componentType.includes("COE_USERS")
      ) {
        await clientService.getPdfFile("COE", data);
      }
      if (
        componentType.includes("USER") &&
        !componentType.includes("COE_USERS")
      ) {
        await clientService.getPdfFile("USER", filteredUserData);
      }
      if (componentType.includes("MyTEAM")) {
        await clientService.getPdfFile("MyTEAM", filteredData, localData);
      }
      if (componentType.includes("COE_USERS")) {
        await clientService.getPdfFile("COE_USERS", data[0].coeId);
      }
      if (componentType.includes("TEAM") && !componentType.includes("MyTEAM")) {
        await clientService.getTeamPdf(
          "TEAM",
          filteredTeamData,
          teamStats,
          localData?.fromD,
          localData?.toD,
          localData?.selectedCoeRange,
          localData?.callScore,
          localData?.pitchScore,
          localData?.aht,
          localData.callsFrom ? localData?.callsFrom : "",
          localData.callsTo ? localData?.callsTo : "",
          dashboardCaller == "DESC"
            ? "top_caller"
            : dashboardCaller == "ASCE"
            ? "bottom_caller"
            : ""
        );
      }
      if (componentType.includes("PRODUCTIVITY")) {
        await clientService.getPdfFile("PRODUCTIVITY");
      }
      if (componentType.includes("CONVERSION_METRICS")) {
        await clientService.getPdfFile("CONVERSION_METRICS");
      }
      if (componentType.includes("DURATION_METRICS")) {
        await clientService.getPdfFile("DURATION_METRICS");
      }
    } catch (error) {
      console.error("Error creating PDF file:", error);
    }
    await setLoading(false);
  };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h + (h == 1 ? " Hr. " : " Hrs. ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " Min. " : " Mins. ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " Sec." : " Secs.") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  const handleDownload = () => {
    if (isExcel) {
      createExcel();
    } else {
      createPdf();
    }
  };

  const colorCode = (d) => {
    if (d <= 100 && d > 90) {
      return (
        <span
          className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-success"} `}
        >
          {" "}
          {`${d}%`}{" "}
        </span>
      );
    } else if (d <= 90 && d > 80) {
      return (
        <span
          className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-warning"} `}
        >
          {" "}
          {`${d}%`}{" "}
        </span>
      );
    } else if (d <= 80 && d >= 0) {
      return (
        <span
          className={`badge rounded-pill btn fw-normal font-13 ${"btn-outline-danger"} `}
        >
          {" "}
          {`${d}%`}{" "}
        </span>
      );
    }
  };

  const getColumnStyle = (column) => {
    const baseStyle = column.getHeaderProps().style || {};

    switch (column.render("Header")) {
      case "Competitor Id":
        return { ...baseStyle /*width: '150px'*/ };
      case "Document Title":
        return { ...baseStyle /*width: '250px'*/ };
      case "Document Description":
        return { ...baseStyle /*width: '300px'*/ };
      // Add more cases for other columns as needed
      default:
        return baseStyle;
    }
  };

  // const coachingTeamRedirection = (row) => {
  //   history.push(`/coaching_team_stats?coachingSessionId=${row.original.sessionId}&name=${row.original.team}`)
  // }

  const coachingUserRedirection = (row) => {
    history.push(`/coaching_user_stats?name=${row.original.assignedTo}&userId=${row.original.agentId}`)
  }

  return (
    <>
      {isOpen
        ? CommonSwitchCase.switchCase(
            setIsOpen,
            componentType,
            buttonValue,
            selectedRowData,
            getCompetitorsList ? getCompetitorsList : getFoldersList,
            folderId,
            getAllCoachingSessionsList
          )
        : ""}
      {/* <div className="d-flex mb-2 odio-tableTop justify-content-between align-items-center"> */}
      <div className="d-flex mb-2 odio-tableTop justify-content-end">
        {isExcelPdfButton &&
          (loading ? (
            <div style={{ height: "2rem" }}>
              <BubbleLoader />
            </div>
          ) : (
            <>
            {/* <div className="d-flex align-items-center">
            <Button
                variant="outline-secondary"
                onClick={prevButton}
                style={{
                  backgroundColor: "#0077b6",
                  color: "white",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  marginRight: "-1px",
                }}
                >
                Prev
              </Button>
              </div> */}
              <div className="d-flex align-items-center">
              <Button
                variant="outline-secondary"
                onClick={() => setIsExcel(true)}
                disabled={isIconDisable}
                style={{
                  backgroundColor: isExcel ? "#0077b6" : "#CACFCD",
                  color: "white",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  marginRight: "-1px",
                }}
                >
                Excel
              </Button>
              <Button
                variant="outline-secondary"
                onClick={() => setIsExcel(false)}
                disabled={isIconDisable}
                style={{
                  backgroundColor: !isExcel ? "#0077b6" : "#CACFCD",
                  color: "white",
                  border: "none",
                  fontWeight: "500",
                  fontSize: "14px",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                >
                Pdf
              </Button>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="bottom"
                overlay={
                  <Tooltip>
                    {isExcel ? "Export Excel" : "Export PDF"}
                  </Tooltip>
                }
                >
                <button
                  onClick={handleDownload}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    padding: "0px 8px",
                    borderRadius: "4px",
                    border: "none",
                    marginLeft: "4px",
                  }}
                >
                  <i
                    className="bx bx-download"
                    style={{
                      color: "#0077b6",
                      fontSize: "20px",
                      fontWeight: "600",
                    }}
                    ></i>
                </button>
              </OverlayTrigger>
              </div>
            </>
          ))}
      </div>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted
                      ? column.isSortedDesc
                        ? "↓ d-flex justify-content-between "
                        : "↑ d-flex justify-content-between"
                      : ""
                  }
                  style={getColumnStyle(column)}
                >
                  <p
                    className={`mb-0 ${
                      column.render("Header") === "Action" ||
                      column.render("Header") === "Pitch Score" ||
                      column.render("Header") === "Call Score" ||
                      column.render("Header") === "Users" ||
                      column.render("Header") === "Caller" ||
                      column.render("Header") === "Conversion Probability" ||
                      column.render("Header") === "opportunity" ||
                      column.render("Header") === "Urgency" ||
                      column.render("Header") === "Background Noise" ||
                      column.render("Header") === "Customer Engagement" ||
                      column.render("Header") === "Customer Satisfaction" ||
                      column.render("Header") === "Media Action"
                        ? "text-center"
                        : ""
                    }`}
                    onClick={pageFunction}
                  >
                    {column.render("Header")}
                  </p>
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            const rowLength = row.cells.length - 1;
            const isAdmin = row.original?.role === "ADMIN";
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.column.Header == "Call Score" ? (
                        <p className="text-center mb-0">
                          {colorCode(cell.value)}
                        </p>
                      ) : cell.column.Header == "Name" ? (
                        <div
                          style={{
                            textTransform: "capitalize",
                            cursor: "pointer",
                          }}
                          className="teamHover"
                        >
                          {cell.value}
                        </div>
                      ) : cell.column.Header == "Call Duration" ? (
                        secondsToHms(cell.value)
                      ) : cell.column.Header == "Id" ? (
                        parseInt(cell.row.id) + 1
                      ) : cell.column.Header == "Actions" ? (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            coachingUserRedirection(cell.row);
                          }}
                          href="#"
                        >
                          View
                        </a>
                      ) : cell.column.Header == "File Name" ? (
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            openSessionInfo(cell);
                          }}
                          href="#"
                        >
                          {cell.value}
                        </a>
                      ) : (
                        // cell.column.Header == 'Member Action' ? (
                        //   <a onClick={(e) => {
                        //     e.preventDefault();
                        //     coachingUserRedirection(cell.row)}} href="#">
                        //       View
                        //   </a>
                        //   ) :
                        cell.render("Cell")
                      )}
                      {rowLength == index ? (
                        <p className="mb-0 text-center d-inline">
                          {isEdit && (
                            <a
                              type="button"
                              className="edit-Emp-member"
                              onClick={() => {
                                setView("EDIT", cell.row.original);
                              }}
                            >
                              <i
                                className="lni lni-pencil mx-1"
                                data-tip="Edit"
                              ></i>
                            </a>
                          )}

                          {(!isAdmin && cell.row.original?.status !== "PUBLISH" || cell.row.original?.status === undefined) && (isDelete && (
                            <a
                              type="button"
                              className="del-coe-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#deleteEmpModal"
                              onClick={() => {
                                setView("DELETE", cell.row.original);
                              }}
                            >
                              <i
                                className="lni lni-archive mx-1"
                                data-tip={
                                  componentType == "MANAGE_COE"
                                    ? "Delete COE"
                                    : componentType == "MANAGE_COMPETITORS"
                                    ? "Delete Competitor"
                                    : componentType == "KNOWLEDGE_BASE"
                                    ? "Delete Session"
                                    : componentType == "USER"
                                    ? "Delete User"
                                    : componentType == "MANAGE_KNOWLEDGE_DOCUMENT"
                                    ? "Delete Document"
                                    : componentType == "PRODUCTIVITY"
                                    ? "Delete Session"
                                    : componentType == "SESSION"
                                    ? "Delete Session"
                                    : "Remove User"
                                }
                              />
                            </a>
                          ))}

                          {isAdd && (
                            <a
                              type="button"
                              className="del-coe-btn"
                              data-bs-toggle="modal"
                              onClick={() => {
                                setView("ADD", cell.row.original);
                              }}
                            >
                              <i
                                className="lni lni-plus mx-1"
                                data-tip={
                                  componentType == "MANAGE_COE"
                                    ? "Assign User"
                                    : ""
                                }
                              />
                            </a>
                          )}
                          {isReco && (
                            <a
                              type="button"
                              className="del-coe-btn"
                              data-bs-toggle="modal"
                              onClick={() => {
                                setView("RECO", cell.row.original);
                              }}
                            >
                              <i className="lni lni-list"></i>
                            </a>
                          )}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {componentType != "MANAGE_REPORTS" ? (
        <div className="odio-tablefooter d-flex justify-content-between align-items-center w-100">
          {componentType !== "MANAGE_KNOWLEDGE_DOCUMENT" && (
            <span>
              Showing {pageIndex * PageSize + 1} -{" "}
              {PageSize * pageIndex + page.length} of {dataLength}{" "}
              {/* {pageIndex + 1} of {pageOptions.length}{' '} */}
            </span>
          )}

          <div>
            {componentType !== "MANAGE_KNOWLEDGE_DOCUMENT" && (
              <Pagination
                className="pagination-bar mb-1"
                currentPage={currentPage}
                totalCount={data.length}
                pageSize={PageSize}
                pageIndex={pageIndex}
                currentPages={currentPages}
                nextPage={nextPage}
                previousPage={previousPage}
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                handlePageIndex={handlePageIndex1}
                resetPagination={resetPagination}
                onRowClick={onRowClick}
              />
            )}
            {/* <ul className="pagination mb-0">
                    <li className="paginate_button page-item previous"><button href="#" className='font-14 fw-normal page-link' onClick={() => previousPage()} disabled={!canPreviousPage} >Prev</button></li>
                    
                    <li className="paginate_button page-item active"><a href="#" className="page-link">{pageOptions.length}</a></li>
                    
                    <li className="paginate_button page-item next"><a href="#" className='font-14 fw-normal page-link' onClick={() => nextPage()} disabled={!canNextPage}>Next</a></li>
                </ul> */}
          </div>
        </div>
      ) : (
        ""
      )}
      <ReactTooltip place="bottom" type="dark" effect="solid" />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    filteredData: state.manage_teams.filteredData,
    filteredUserData: state.manage_users.filteredUserData,
    filteredTeamData: state.manage_team.filteredTeamData,
    teamStats: state.manage_team.teamStats,
  };
};
export default connect(mapStateToProps)(Table);
